import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";
import styled from 'styled-components';
import { IoCloseCircleOutline } from "react-icons/io5";

const ScreenshotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
`;

const ScreenshotItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

const Caption = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  transform: translateY(100%);
  transition: transform 0.3s ease;

  ${ScreenshotItem}:hover & {
    transform: translateY(0);
  }
`;

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  max-width: 90%;
  max-height: 90%;
  position: relative;

  img {
    max-width: 100%;
    max-height: 90vh;
    display: block;
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Screenshots = () => {
  const [screenshots, setScreenshots] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    const importAll = (r) => {
      return r.keys().map((fileName) => ({
        path: r(fileName),
        caption: fileName.replace('./', '').replace(/\.[^/.]+$/, '').replace(/-/g, ' ').replace(/_/g, ' ')
      }));
    }
    
    const images = importAll(require.context('../../../Images/screenshots', false, /\.(png|jpe?g|svg)$/));
    setScreenshots(images);
  }, []);

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) closeModal();
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Podium Screenshots</h2>
          <ScreenshotGrid>
            {screenshots.map((screenshot, index) => (
              <ScreenshotItem key={index} onClick={() => openModal(screenshot)}>
                <img src={screenshot.path} alt={`Screenshot ${index + 1}`} />
                <Caption>{screenshot.caption}</Caption>
              </ScreenshotItem>
            ))}
          </ScreenshotGrid>
        </div>
      </Row>
      {selectedImage && (
        <Modal onClick={closeModal}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage.path} alt={selectedImage.caption} />
            <CloseButton onClick={closeModal}>
              <IoCloseCircleOutline size={30} color="white" />
            </CloseButton>
          </ModalContent>
        </Modal>
      )}
    </Container>
  );
};

export default Screenshots;