import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";

const DownloadButton = ({ fileId, variant = "warning" }) => {
  const [downloadFile, setDownloadFile] = useState(null);
  const [downloadLoading, setDownloadLoading] = useState(false); // State to track download progress

  useEffect(() => {
    const fetchDownloadConfig = async () => {
      try {
        const response = await fetch(
          "https://podiumtheory.com/downloads/downloadsConfig.json"
          //"http://localhost:3002/downloads/downloadsConfig.json" // Use local server for testing
        );
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const config = await response.json();
        const fileConfig = config.files.find((file) => file.id === fileId);
        if (fileConfig) {
          setDownloadFile(fileConfig);
        } else {
          console.error("File not found in config:", fileId);
        }
      } catch (error) {
        console.error("Error fetching download config:", error);
      }
    };

    if (fileId) {
      fetchDownloadConfig();
    }
  }, [fileId]);

  const getPodiumInstallerUrl = async () => {
    try {
      const response = await fetch(
        "https://podiumtheory.com/api/downloads/installer/latest"
      );
      if (response.ok) {
        const installer = await response.json();
        return installer.url;
      }
    } catch (error) {
      console.error("Error fetching Podium installer:", error);
    }
  };

  const handleDownload = async () => {
    if (downloadFile) {
      setDownloadLoading(true); // Start loading

      if (downloadFile.id === "podiumInstaller") {
        downloadFile.path = await getPodiumInstallerUrl();
      }

      fetch(downloadFile.path)
        .then((response) => response.blob())
        .then((blob) => {
          const fileURL = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = fileURL;
          link.download = downloadFile.downloadName; // Use the downloadName for the actual file name to save
          link.click();
          setDownloadLoading(false); // End loading
        })
        .catch((error) => {
          console.error("Error downloading file:", error);
          setDownloadLoading(false); // End loading in case of error
        });
    }
  };

  return (
    <Button
      variant={variant}
      style={{ width: "100%" }}
      onClick={handleDownload}
      disabled={downloadLoading} // Disable button when loading
    >
      {downloadLoading
        ? "Downloading..."
        : downloadFile
        ? `${downloadFile.displayName}`
        : "Download"}
    </Button>
  );
};

export default DownloadButton;
