import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const PrivacyPolicy = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>Privacy Policy</h2>
          <span className="important">Last Updated: September 12, 2024</span>
          <br />
          <br />
          <p>
            Welcome to Podium, provided by Delta Zero, LLC {`(`}"we", "our", or
            "us"{`)`}. Your privacy is paramount to us. This Privacy Policy
            outlines how we collect, use, protect, and share information about
            you through PodiumTheory.com and our Podium desktop application.
          </p>
          <h6>1. Information Collection</h6>
          <ul>
            <li>
              <b>Personal Data:</b> We no longer collect or store any personal data, including your name, email address, or game telemetry data. User accounts and our database have been disabled.
            </li>
            <li>
              <b>Automatic Data Collection:</b> We may collect error logs that contain no user-identifying information.
            </li>
            <li>
              <b>Data Storage:</b> All data generated by the app is stored locally on your device and is not accessible to us.
            </li>
            <li>
              <b>Payment Information:</b> Optional donations are handled by Stripe, Inc. without storing details on our end.
            </li>
          </ul>
          <h6>2. Use of Information</h6>
          <ul>
            <li>
              The only data we may collect are error logs, non-identifying, for debugging purposes and service improvement.
            </li>
          </ul>
          <h6>3. Data Processing</h6>
          <ul>
            <li>
              We no longer process any user profile information. All data remains local on your machine.
            </li>
          </ul>
          <h6>4. Sharing of Information</h6>
          <ul>
            <li>No sharing of any data with third parties, period.</li>
          </ul>
          <h6>5. User Consent and Opt-Out</h6>
          <ul>
            <li>
              By using our services, you consent to the collection of non-identifying error logs. You can opt out by uninstalling the app.
            </li>
          </ul>
          <h6>6. Data Security</h6>
          <ul>
            <li>
              Although we no longer collect or store personal data, we still implement security measures to ensure that your data stored locally on your machine is not compromised by the app. The app is not connected to the internet and does not communicate with any external servers beyond the initial installation and update process.
            </li>
          </ul>
          <h6>7. User Rights</h6>
          <ul>
            <li>
              As no user data is collected or stored, there is no need for access, correction, or deletion rights.
            </li>
          </ul>
          <h6>8. International Data Transfer</h6>
          <ul>
            <li>
              We do not transfer any data, as all information remains local to your device.
            </li>
          </ul>
          <h6>9. Data Breach Response</h6>
          <ul>
            <li>Since no personal data is collected or stored, the risk of a data breach is nonexistent. However, in the event of a security vulnerability in the app, we will notify users promptly via our Discord server.</li>
          </ul>
          <h6>10. Policy Updates</h6>
          <ul>
            <li>
              We reserve the right to modify this privacy policy. Any changes will be posted on our website and users will be notified via our Discord server.
            </li>
          </ul>
          <h6>11. Contact Information</h6>
          <ul>
            <li>
              Reach out to{" "}
              <a
                className={gStyle.cyan}
                style={{ textDecoration: "none" }}
                href="mailto:support@podiumtheory.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                support@podiumtheory.com
              </a>{" "}
              for any privacy-related queries.
            </li>
          </ul>
          <br />
          <p>
            By using Podium, you acknowledge and agree to the terms outlined in
            this Privacy Policy and the accompanying{" "}
            <NavLink to="/about/terms-of-service">Terms of Service</NavLink>.
          </p>
        </div>
      </Row>
    </Container>
  );
};

export default PrivacyPolicy;
