import React, { Fragment, useState, useEffect } from "react";
import { Button, Container, Col, Row, Card } from "react-bootstrap";
import style from "./Home.module.scss";
import gStyle from "../../SCSS/global.module.scss";
import { Link } from "react-router-dom";
import Wilson from "../../Images/WilsonHughes.png";
import George from "../../Images/GeorgeSimmons.png";
import Lucas from "../../Images/LucasBlakeley.png";
import BG01 from "../../Images/home/bg_1.jpg";
import BG02 from "../../Images/home/bg_2.jpg";
import BG03 from "../../Images/home/bg_3.png";
import BG04 from "../../Images/home/bg_4.png";
import BG05 from "../../Images/home/bg_5.png";
import BG06 from "../../Images/home/bg_6.png";

const backgroundImages = [BG01, BG02, BG03, BG04, BG05, BG06];

const LandingPage = () => {
  const [currentBackground, setCurrentBackground] = useState(0);
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    // Preload images and then set imagesLoaded to true
    Promise.all(
      backgroundImages.map((image) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = image;
          img.onload = resolve;
        });
      })
    ).then(() => {
      setImagesLoaded(true);
    });

    const interval = setInterval(() => {
      setCurrentBackground(
        (prevBackground) => (prevBackground + 1) % backgroundImages.length
      );
    }, 7000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Fragment>
      <section className={style.hero}>
        {!imagesLoaded ? (
          <div className={style.placeholderBackground}></div>
        ) : (
          backgroundImages.map((image, index) => (
            <div
              key={index}
              className={`${style["background-image"]} ${
                index === currentBackground ? style.active : ""
              }`}
              style={{ backgroundImage: `url(${image})` }}
            ></div>
          ))
        )}
        <div className={`${style.heroContent}`}>
          <h1>PODIUM</h1>
          <div
            style={{
              fontSize: "14px",
            }}
          >
            FOR F1 SIM RACING
          </div>
          <br />
          <Link to="/support">
            <Button variant="warning">DOWNLOAD NOW</Button>
          </Link>
          <br /><br />
          <div
            style={{
              fontSize: "14px",
            }}
          >
            Includes MoTeC Logger and Race Director
          </div>
        </div>
      </section>
      <section className={`s2 ${gStyle.darkGrayBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.cyan} ${gStyle.cyanTs} mb-4`}
              >
                Podium
              </h2>
              <p className={`${gStyle.mdText} ${gStyle.wh}`}>
                Delta Zero Production (DZP) designed Podium to introduce a new
                era in F1 sim racing. This cutting-edge platform was
                meticulously crafted to enhance the experience for drivers,
                engineers, and league managers. Podium offers a
                user-friendly and intuitive suite of tools, redefining the way
                individuals interact with F1 sim racing. Podium is the only app capable 
                of exporting MoTeC I2 Pro telemetry data for F1 games. By integrating advanced
                engineering, data analysis, and race director tools, Podium empowers the F1
                sim racing community to unlock their full potential both on and
                off the track.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={`${style.s5} ${gStyle.trackImageBG}`}>
        <Container className={`${gStyle.container} ${gStyle.containerMd}`}>
          <Row>
            <Col>
              <h2
                className={`${gStyle.lgText} ${gStyle.magenta} ${gStyle.magentaTs} mb-4`}
              >
                Testimonials
              </h2>
            </Col>
          </Row>
          <Row className={style.row2}>
            <Col>
              <Card>
                <Card.Body
                  style={{
                    marginTop: "80px",
                    marginLeft: "-9px",
                    marginRight: "-9px",
                    paddingTop: "25px",
                    lineHeight: "2.0",
                  }}
                >
                  <img
                    src={Lucas}
                    alt="Lucas Blakeley"
                    className={`${gStyle.cyanFilter2}`}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-5px",
                      left: "25%",
                    }}
                  />
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10077;
                  </span>{" "}
                  Podium has been an essential tool in helping us gather and
                  understand data. It provides us with that insightful edge
                  while maintaining efficiency at the same time. A must-use!{" "}
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10078;
                  </span>
                </Card.Body>
                <Card.Footer
                  style={{
                    textAlign: "right",
                    marginTop: "-10px",
                    marginLeft: "-10px",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    Lucas Blakeley
                  </span>
                  <br />
                  <span className={`${gStyle.mclaren} ${gStyle.mclarenTs}`}>
                    F1 Esports Pro Series Champion
                  </span>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body
                  style={{
                    marginTop: "80px",
                    marginLeft: "-9px",
                    marginRight: "-9px",
                    paddingTop: "25px",
                    lineHeight: "2.0",
                  }}
                >
                  <img
                    src={George}
                    alt="George Simmons"
                    className={`${gStyle.yellowFilter2}`}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-5px",
                      left: "25%",
                    }}
                  />
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10077;
                  </span>{" "}
                  Behind the scenes, Podium has given us a competitive advantage
                  by enabling us to work with our setups and data much more
                  efficiently than before, streamlining our processes and
                  delivering better results.{" "}
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10078;
                  </span>
                </Card.Body>
                <Card.Footer
                  style={{
                    textAlign: "right",
                    marginTop: "-10px",
                    marginLeft: "-10px",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    George Simmons
                  </span>
                  <br />
                  <span className={`${gStyle.mclaren} ${gStyle.mclarenTs}`}>
                    Formula 1 Engineer
                  </span>
                </Card.Footer>
              </Card>
            </Col>
            <Col>
              <Card>
                <Card.Body
                  style={{
                    marginTop: "80px",
                    marginLeft: "-9px",
                    marginRight: "-9px",
                    paddingTop: "25px",
                    lineHeight: "2.0",
                  }}
                >
                  <img
                    src={Wilson}
                    alt="Wilson Hughes"
                    className={`${gStyle.magentaFilter2}`}
                    style={{
                      height: "100px",
                      width: "100px",
                      borderRadius: "50px",
                      position: "absolute",
                      top: "-5px",
                      left: "25%",
                    }}
                  />
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10077;
                  </span>{" "}
                  Podium is such an amazing tool. Honestly, we've had stuff
                  similar to it before, but nothing this good. Really, really
                  helpful. You guys are absolute legends.{" "}
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    &#10078;
                  </span>
                </Card.Body>
                <Card.Footer
                  style={{
                    textAlign: "right",
                    marginTop: "-10px",
                    marginLeft: "-10px",
                    width: "100%",
                    marginBottom: "30px",
                  }}
                >
                  <span
                    className={`${gStyle.mdText} ${gStyle.cyan} ${gStyle.cyanTs}`}
                  >
                    Wilson Hughes
                  </span>
                  <br />
                  <span className={`${gStyle.mclaren} ${gStyle.mclarenTs}`}>
                    Esports Driver
                  </span>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </Fragment>
  );
};

export default LandingPage;
