import React from "react";
import { NavLink, Outlet } from "react-router-dom";
import "./SidebarLayout.scss"; // Assume you have some CSS to style your layout

const SidebarLayout = () => {
  const getActiveClass = (isActive, baseClass, activeClass) =>
    isActive ? `${baseClass} ${activeClass}` : baseClass;

  return (
    <div className="body-wrapper">
      <div className="sidebar-content-container">
        <aside className="sidebar">
          <div className="title">About</div>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/about/podium-for-f1"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-yellow"
                    )
                  }
                >
                  Podium for F1
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/screenshots"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-yellow"
                    )
                  }
                >
                  Podium Screenshots
                </NavLink>
              </li>
            </ul>
          </nav>
          <br />
          <div className="title">Knowledge Base</div>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/about/faq"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-green"
                    )
                  }
                >
                  Frequently Asked Questions
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/how-to-guides"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-green"
                    )
                  }
                >
                  How-To Guides
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/video-tutorials"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-green"
                    )
                  }
                >
                  Video Tutorials
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/release-notes"
                  className={({ isActive }) =>
                    getActiveClass(
                      isActive,
                      "link-default",
                      "link-active-green"
                    )
                  }
                >
                  Release Notes
                </NavLink>
              </li>
            </ul>
          </nav>
          <br />
          <div className="title">Privacy & Terms</div>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/about/privacy-policy"
                  className={({ isActive }) =>
                    getActiveClass(isActive, "link-default", "link-active-red")
                  }
                >
                  Privacy Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about/terms-of-service"
                  className={({ isActive }) =>
                    getActiveClass(isActive, "link-default", "link-active-red")
                  }
                >
                  Terms of Service
                </NavLink>
              </li>
            </ul>
          </nav>
        </aside>
        <main className="content">
          <Outlet /> {/* Main content */}
        </main>
      </div>
    </div>
  );
};

export default SidebarLayout;
