import React from "react";
import { Container, Row } from "react-bootstrap";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";
import DownloadButton from "../../../Components/DownloadButton";

const HowToGuides = () => {
  return (
    <Container>
      <Row>
        <div className="spacing">
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>How-To Guides</h2>
          <p>
            Our How-To Guides are designed to help you get started quickly using
            the modules available in Podium.
          </p>
          <div className="my-3">
            <DownloadButton fileId="motecLoggerGuide" variant="info" />
          </div>
          <br />
          <p>
            To recieve UDP telemetry data remotely from a friend or league participant, 
            you may need to connect via a Virtual Local Area Network. We recommend using Radmin VPN:
          </p>
          <div className="my-3">
            <DownloadButton fileId="radminVPNGuide" variant="info" />
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default HowToGuides;
