import React from "react";
import { Container, Row } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import gStyle from "../../../SCSS/global.module.scss";
import "./ContentStyle.scss";

const PodiumForF1 = () => {
  return (
    <Container>
      <Row>
        <div>
          <h2 className={`${gStyle.wh} ${gStyle.whTs}`}>
            Podium for F1 Sim Racing
          </h2>
          <h5>Elevate Your Racing Experience</h5>
          <p>
            Discover <b>Podium</b>, the future of F1 sim racing. Our platform
            transforms your racing with intuitive tools and advanced features:
          </p>
          <ul>
            <li>
              <b>MoTeC Logger:</b> Sharpen your skills and strategy utilizing the most advanced data 
                analysis tool on the market, MoTeC I2 Pro. 
                <br /><br />
                Podium is the only app capable of exporting telemetry data in the MoTeC i2 Pro format.
                We provide a I2 Pro Workspace that utilizes hundreds of channels of data to help you
                find time on the track.
                <br /><br />
                MoTeC i2 Pro is powerful software specifically designed for
                motorsports applications, and trusted by real life F1 engineers. It offers extensive
                analysis tools and innovative data management features, catering to professional
                race teams. The software allows for the analysis of multiple graphs, gauges, and
                reports simultaneously and is highly customizable to meet specific user
                requirements, including advanced mathematics, multiple overlay laps, and unlimited
                components, workbooks, and worksheets. These features are particularly useful for
                detailed analysis and optimization of racing performance.
            </li>
            <li>
              <b>Race Director:</b> Our Race Director module allows users to record entire sessions for 
              later review in a full 3D recreation utilizing the UDP telemetry data from the game.
              <br /><br />
              Particularly useful for incident reviews, League Managers can use this tool to make informed 
              decisions on reported incidents without requiring the drivers to submit video evidence.
              <br /><br />
              Features include:
              <ul>
                <li>3D environment, including track surface and cars</li>
                <li>Driver position tracking</li>
                <li>Incident detection</li>
                <li>Session playback from any driver's perspective</li>
                <li>Side by side comparison</li>
                <li>Driver input and other user data overlays</li>
              </ul>
            </li>
          </ul>
          <p>
            Explore how Podium can empower every aspect of your F1 sim racing
            journey.
          </p>
          <NavLink to="/about/screenshots" style={{ marginLeft: "15px" }}>
            View Podium Screenshots
          </NavLink>
        </div>
      </Row>
    </Container>
  );
};

export default PodiumForF1;
