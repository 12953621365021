import React, { Fragment } from "react";
import MainScreen from "../../Components/MainScreen";
import { Col, Card, Row } from "react-bootstrap";
import styled from "styled-components";
import DZPLogo from "../../Images/BluePurple.svg";
import { LinkContainer } from "react-router-bootstrap";
import DownloadButton from "../../Components/DownloadButton";
import "./PodiumSupportScreen.scss";
import gStyle from "../../SCSS/global.module.scss";

const LogoImg = styled.div`
  display: flex;
  text-decoration: none;
  width: 140px;
  height: 120px;
  margin-left: 20px;

  img {
    width: 140px;
    height: 100%;
  }
  transition: filter 0.3s ease;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-1px);
    filter: drop-shadow(1px 1px 2px #111);
  }
`;

const PodiumSupportScreen = () => {
  return (
    <Fragment>
      <MainScreen
        title="Downloadable Resources"
      >
        <Card className="mb-1 mt-1">
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card className="mb-1">
                  <Card.Header
                    className={`${gStyle.yellow} ${gStyle.yellowTs} ${gStyle.smdText}`}
                  >
                    Downloads
                  </Card.Header>
                  <Card.Body>
                    <div className="mt-1">
                      <DownloadButton fileId="podiumInstaller" />
                    </div>
                    <div className="mt-3">
                      <DownloadButton fileId="i2proworkspace" />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-1">
                  <Card.Header
                    className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
                  >
                    Guides
                  </Card.Header>
                  <Card.Body>
                    <div className="mt-1">
                      <DownloadButton
                        fileId="motecLoggerGuide"
                        variant="info"
                      />
                    </div>
                    <div className="mt-3">
                      <DownloadButton
                        fileId="radminVPNGuide"
                        variant="info"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </MainScreen>
      <MainScreen
        title="Support Resources"
        className={`supportResources ${gStyle.trackImageBG}`}
      >
        <Card className="mb-1 mt-1">
          <Card.Body>
            <Row>
              <Col md={6}>
                <Card className="mb-1">
                  <Card.Header
                    className={`${gStyle.cyan} ${gStyle.cyanTs} ${gStyle.smdText}`}
                  >
                    Knowledge Base
                  </Card.Header>
                  <Card.Body>
                    <LinkContainer
                      to="/about/faq"
                      className={`${gStyle.green} hoverHighlight`}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <span>Frequently Asked Questions</span>
                    </LinkContainer>
                    <br />
                    <LinkContainer
                      to="/about/how-to-guides"
                      className={`${gStyle.green} hoverHighlight`}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <span>How-To Guides</span>
                    </LinkContainer>
                    <br />
                    <LinkContainer
                      to="/about/video-tutorials"
                      className={`${gStyle.green} hoverHighlight`}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <span>Video Tutorials</span>
                    </LinkContainer>
                    <br />
                    <LinkContainer
                      to="/about/release-notes"
                      className={`${gStyle.green} hoverHighlight`}
                      style={{ textDecoration: "none", cursor: "pointer" }}
                    >
                      <span>Release Notes</span>
                    </LinkContainer>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="mb-1">
                  <Card.Header
                    className={`${gStyle.red} ${gStyle.redTs} ${gStyle.smdText}`}
                  >
                    External Support
                  </Card.Header>
                  <Card.Body>
                    <a
                      className={gStyle.yellow}
                      style={{ textDecoration: "none" }}
                      href="https://help.ea.com/en/help-top-issues/?product=f1-24"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EA's F1 24 Support
                    </a>
                    <br />
                    <a
                      className={gStyle.yellow}
                      style={{ textDecoration: "none" }}
                      href="https://answers.ea.com/t5/F1-24/ct-p/f1-24-en"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      EA's F1 24 Answers HQ
                    </a>
                    <br />
                    <a
                      className={gStyle.yellow}
                      style={{ textDecoration: "none" }}
                      href="https://www.motec.com.au/downloads"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      MoTeC's I2 Pro Software
                    </a>
                    <br />
                    <a
                      className={gStyle.yellow}
                      style={{ textDecoration: "none" }}
                      href="https://www.radmin-vpn.com"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Radmin VPN Software
                    </a>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </MainScreen>
      <MainScreen title="Support Information" className={"supportInformation"}>
        <Row>
          <Col md={6} className="mb-3">
            <div className="supportContainer">
              If you have specific questions or issues, you can submit a support
              ticket on Discord in our dedicated support channel. Our team will
              be there to assist you promptly.
              <br />
              <Col style={{ width: "180px" }}>
                <a
                  href="https://discord.gg/deltazero"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <LogoImg>
                    <img src={DZPLogo} alt="Delta Zero Discord" />
                  </LogoImg>
                </a>
                <a
                  className={gStyle.cyan}
                  style={{ textDecoration: "none" }}
                  href="https://discord.gg/deltazero"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  discord.gg/deltazero
                </a>
              </Col>
            </div>
          </Col>
          <Col md={6}>
            <hr className="hr-add" />
            <div style={{ padding: "0 12px 15px 12px", marginTop: "10px" }}>
                If you can't access Discord or prefer email, you can reach out
                to our support team at{" "}
                <a
                  className={gStyle.cyan}
                  style={{ textDecoration: "none" }}
                  href="mailto:support@podiumtheory.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  support@podiumtheory.com
                </a>
                . We'll get back to you as soon as possible.
            </div>
          </Col>
        </Row>
      </MainScreen>
    </Fragment>
  );
};

export default PodiumSupportScreen;
