import React, { useState } from "react";
import {
  Container,
  Offcanvas,
  Nav,
  Navbar,
  NavDropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useNavigate } from "react-router-dom";

import "./Header.scss";

import { Logo } from "../logo";

const Header = () => {
  const navigate = useNavigate();
  const [showNav, setShowNav] = useState(false);

  const handleNavClick = (path) => {
    navigate(path);
    setShowNav(false);
  };

  return (
    <Navbar
      className="fixed-top py-2 navbar-dark bg-dark"
      bg="primary"
      expand="md"
      variant="dark"
    >
      <Container className="lg" expand="md">
        <Logo />
        <LinkContainer to="/">
          <Navbar.Brand className="me-auto brand-text">
            Podium Theory
          </Navbar.Brand>
        </LinkContainer>
        <Navbar.Toggle
          aria-controls="offcanvasNavbar-expand-md"
          onClick={() => setShowNav(true)}
        />
        <Navbar.Offcanvas
          id="offcanvasNavbar-expand-lg"
          aria-labelledby="offcanvasNavbarLabel-expand-md"
          placement="end"
          show={showNav}
          onHide={() => setShowNav(false)}
        >
          <Offcanvas.Header
            closeButton
            closeVariant="white"
            className="darkPurple"
          >
            <Offcanvas.Title id="offcanvasNavbarLabel-expand-md">
              PodiumTheory.com
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 py-1">
              <NavDropdown
                title="About"
                id="offcanvasNavbarDropdown-expand-md"
                style={{
                  marginRight: "5px",
                  marginBotton: "3px",
                }}
              >
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/podium-for-f1")}
                >
                  Podium for F1
                </NavDropdown.Item>

                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/screenshots")}
                    >
                      Podium Screenshots
                    </NavDropdown.Item>
                  </>
                )}
                <NavDropdown.Divider />

                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/faq")}
                >
                  {showNav ? "Frequently Asked Questions" : "Knowledge Base"}
                </NavDropdown.Item>
                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/how-to-guides")}
                    >
                      How to Guides
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/video-tutorials")}
                    >
                      Video Tutorials
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/release-notes")}
                    >
                      Release Notes
                    </NavDropdown.Item>
                  </>
                )}

                <NavDropdown.Divider />
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/about/privacy-policy")}
                >
                  {showNav ? "Privacy Policy" : "Privacy & Terms"}
                </NavDropdown.Item>
                {showNav && (
                  <>
                    <NavDropdown.Item
                      className="my-1"
                      onClick={() => handleNavClick("/about/terms-of-service")}
                    >
                      Terms of Service
                    </NavDropdown.Item>
                  </>
                )}
              </NavDropdown>
              <NavDropdown
                title="Podium"
                id="offcanvasNavbarDropdown-expand-md"
                style={{
                  marginRight: "5px",
                  marginBotton: "3px",
                }}
              >
                <NavDropdown.Item
                  className="my-1"
                  onClick={() => handleNavClick("/support")}
                >
                  Downloads
                </NavDropdown.Item>
              </NavDropdown>
              <span style={{ margin: "5px" }}></span>
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default Header;
