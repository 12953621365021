import { Fragment } from "react";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Home from "./Screens/HomePage/Home";
import AboutPodiumScreen from "./Screens/AboutScreens/AboutContent/PodiumForF1";
import AboutSidebarLayout from "./Screens/AboutScreens/SidebarLayout";
import AboutScreenshotsScreen from "./Screens/AboutScreens/AboutContent/Screenshots";
import AboutFAQScreen from "./Screens/AboutScreens/AboutContent/FAQ";
import AboutTermsScreen from "./Screens/AboutScreens/AboutContent/Terms";
import AboutGuidesScreen from "./Screens/AboutScreens/AboutContent/HowToGuides";
import AboutTutorialsScreen from "./Screens/AboutScreens/AboutContent/VideoTutorials";
import AboutReleaseNotesScreen from "./Screens/AboutScreens/AboutContent/ReleaseNotes";
import AboutPrivacyScreen from "./Screens/AboutScreens/AboutContent/PrivacyPolicy";
import PodiumSupportScreen from "./Screens/PodiumScreens/PodiumSupportScreen";
import { Route, Routes, useLocation } from "react-router-dom";

import gStyle from "./SCSS/global.module.scss";

function App() {
  let { pathname } = useLocation();

  if (pathname === "/") {
    pathname = "/home";
  }

  pathname = pathname.split("/");

  return (
    <Fragment>
      <Header />
      <main
        id={pathname[1]}
        className={`App ${pathname[1] !== "home" ? gStyle.inner : ""}`}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          {/* Paths to add */}
          <Route path="/about" element={<AboutSidebarLayout />}>
            <Route
              path="/about/podium-for-f1"
              element={<AboutPodiumScreen />}
            />
            <Route
              path="/about/screenshots"
              element={<AboutScreenshotsScreen />}
            />
            <Route path="/about/faq" element={<AboutFAQScreen />} />
            <Route
              path="/about/how-to-guides"
              element={<AboutGuidesScreen />}
            />
            <Route
              path="/about/video-tutorials"
              element={<AboutTutorialsScreen />}
            />
            <Route
              path="/about/release-notes"
              element={<AboutReleaseNotesScreen />}
            />
            <Route
              path="/about/privacy-policy"
              element={<AboutPrivacyScreen />}
            />
            <Route
              path="/about/terms-of-service"
              element={<AboutTermsScreen />}
            />
          </Route>
          {/* podium paths */}
          <Route path="/support" element={<PodiumSupportScreen />} />
          {/* Catch-all route */}
          <Route path="*" element={<Home />} />
        </Routes>
      </main>
      <Footer />
    </Fragment>
  );
}

export default App;
